<template>
  <div>
    <v-list
      v-if="elementActive === null"
      dense
      nav
      class="py-0"
    >
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        @click="elementActive = item.element"
      >
        <v-icon>{{ item.icon }}</v-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div v-else-if="elementActive !== null">
      <component :is="elementActive" creator="theme" @addBackPattern="(motif) => { $emit('addBackPattern', motif) }" @closeElement="elementActive = null" @add="emit" @renderAll="$emit('renderAll')" :obj="objSelected"
                 :refreshSetting="refreshSetting" :fonts="fonts" :fcanvas="fcanvas" :fontsLoading="fontsLoading"></component>
      <v-btn v-if="objSelected" @click="removeObj" v-t="'General.Delete'"></v-btn>
    </div>
    <v-checkbox v-model="show" :label="$t('General.ShowGuides')" @change="toggleGuidelines" />
    <v-select
      v-model="inputValue"
      :items="templates"
      item-text="name"
      item-value="id"
      :label="$t('ThemeCreator.SelectTemplate')"
      filled
      ref="select"
      @change="change"
    ></v-select>
    <v-dialog ref="dialog" v-model="dialog">
      <v-card class="dialog-content">
        <v-card-title v-t="'ThemeCreator.TemplateChangeAlert'" class="dialog-title"></v-card-title>
        <v-card-actions class="justify-space-between">
          <v-btn text @click="discardChange" v-t="'General.Cancel'"></v-btn>
          <v-btn color="primary" text @click="acceptChange" v-t="'General.Accept'"></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import DrawerRightAdd from './DrawerRightAdd'
  import DrawerRightCalques from './DrawerRightCalques'
  import DrawerRightMotif from './DrawerRightMotif'
  import SettingsSquare from './SettingsSquare'
  import SettingsText from './SettingsText'
  import SettingsLine from './SettingsLine'
  import SettingsSvg from './SettingsSvg'
  import SettingsCircle from './SettingsCircle'
  import SettingsImage from './SettingsImage'
  import SettingsMask from './SettingsMask'

  export default {
    props: [
      'objSelected',
      'refreshSetting',
      'fonts',
      'fcanvas',
      'fontsLoading',
      'showGuidelines'
    ],
    components: {
      SettingsMask
    },
    name: 'DrawerRightTheme',
    data () {
      return {
        elementActive: null,
        show: this.showGuidelines,
        items: [
          { title: this.$t('General.AddItem'), icon: 'mdi-apps', element: DrawerRightAdd },
          { title: this.$t('General.Layers'), icon: 'mdi-content-copy', element: DrawerRightCalques },
          { title: this.$t('General.Patterns'), icon: 'mdi-checkerboard', element: DrawerRightMotif }
        ],
        templates: [],
        selectedTemplate: null,
        inputValue: null,
        dialog: false
      }
    },
    watch: {
      showGuidelines () {
        this.updateElementActive()
      },
      objSelected () {
        this.updateElementActive()
      },
      show (newValue) {
        this.$emit('showGuidelines', newValue)
      }
    },
    created () {
      this.updateElementActive()
    },
    mounted () {
      this.getTemplates()
    },
    methods: {
      discardChange () {
        this.inputValue = this.selectedTemplate
        this.dialog = false
      },
      acceptChange () {
        this.selectedTemplate = this.inputValue
        const newTemplate = this.templates.find(el => el.id === this.selectedTemplate)
        this.$emit('selectTemplate', newTemplate)
        this.dialog = false
      },
      change () {
        this.dialog = true
      },
      toggleGuidelines () {
        this.updateElementActive()
      },
      emit (obj) {
        this.$emit('add', obj)
      },
      removeObj () {
        this.$emit('remove', this.objSelected)
      },
      updateElementActive () {
        this.elementActive = null
        if (this.objSelected != null) {
            switch (this.objSelected.type) {
                case 'rect':
                case 'square':
                  this.elementActive = SettingsSquare
                  break
                case 'line':
                    this.elementActive = SettingsLine
                    break
                case 'text':
                case 'i-text':
                    this.elementActive = SettingsText
                    break
                case 'group':
                case 'path':
                case 'svg':
                  this.elementActive = SettingsSvg
                  break
                case 'image':
                  this.elementActive = SettingsImage
                  break
                case 'circle':
                  this.elementActive = SettingsCircle
                  break
          }
        }
        if (this.elementActive) {
          this.elementActive.propsData = {
            showGuidelines: this.showGuidelines,
            selectedTemplate: this.selectedTemplate
          }
        }
      },
      getTemplates () {
        this.templates = this.$store.getters['GET_ALL_TEMPLATES']()
        this.selectedTemplate = this.inputValue = this.$store.state.selectedTemplate.id
      }
    }
  }
</script>

<style scoped>
  .dialog-content {
    position: fixed;
    z-index: 13;
    top: 50%;
    left: 50%;
    background-color: white;
    transform: translate(-50%, -50%);
    text-align: center;
    border-radius: 2px;
    width: 80%;
    max-width: 400px;
    border-radius: 5px;
  }
  .dialog-title {
    font-size: 15px;
    word-break: break-word;
  }
  .justify-space-between {
    justify-content: space-between;
    width: 100%;
  }
</style>
