<template>
  <div ref="container">
    <canvas id="canvas"></canvas>
  </div>
</template>

<script>
import { fabric } from 'fabric'

export default {
  props: [
    'width',
    'height'
  ],
  mounted () {
    this.initCanvas()
  },
  methods: {
    initCanvas () {
      const newCanvas = document.getElementById('canvas')
      newCanvas.width = this.width
      newCanvas.height = this.height
      const fcanvas = new fabric.Canvas('canvas', {
        width: this.width,
        height: this.height
      })
      fcanvas.renderAll()
      this.$emit('fcanvas', fcanvas)
    }
  }
}
</script>

<style>
.canvas-container {
  background-color: white;
  left: 25vw !important;
  top: max(calc(63vh - 25vw), 128px + 50px);
  position: fixed !important;
}
</style>
