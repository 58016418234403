<template>
  <div>
    <v-list
      dense
      nav
      class="py-0"
    >
      <v-list-item>
        <v-list-item-content>
          <v-btn @click="$emit('closeElement')" v-t="'General.Return'"></v-btn>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title v-t="'ThemeCreator.PatternPreview'"></v-list-item-title>
          <v-select
            v-model="motifSelected"
            :items="motifs"
            item-text="name"
            return-object
          ></v-select>
        </v-list-item-content>
      </v-list-item>
      <div v-if="motifSelected && motifSelected.svgPath">
        <v-list-item>
          <v-list-item-content>
            <img style="width: 100%" :src="$axios.defaults.baseURL + '/downloadSvg?filename=' + motifSelected.svgPath"/>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-btn @click="addBackPattern(motifSelected)" v-t="'General.Apply'"></v-btn>
          </v-list-item-content>
        </v-list-item>
      </div>
    <v-list-item>
      <v-list-item-content>
        <v-btn @click="removeBackPattern" v-t="'General.Delete'"></v-btn>
      </v-list-item-content>
    </v-list-item>
    </v-list>
  </div>
</template>

<script>
  export default {
    props: [
      'fcanvas'
    ],
    name: 'DrawerRightMotif',
    data () {
      return {
        motifs: [],
        motifSelected: null
      }
    },
    created () {
      this.getMotifs()
    },
    methods: {
      getMotifs () {
        this.$store.dispatch('getAllDecos').then(() => {
          this.motifs = this.$store.getters['GET_DECOS']()
        })
      },
      addBackPattern (motif) {
        this.removeBackPattern()
        this.$emit('addBackPattern', motif)
      },
      removeBackPattern () {
        var backPattern = this.fcanvas.getObjects().find((o) => {
          return o.name === 'backPattern'
        })
        if (backPattern) {
          this.fcanvas.remove(backPattern)
        }
      }
    }
  }
</script>

<style scoped>

</style>
