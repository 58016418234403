<template>
  <div>
    <v-list
        dense
        nav
        class="py-0"
    >
      <v-list-item>
        <v-text-field :label="$t('SettingsItems.Width')" type="number" v-model="size" @input="updateObj()"></v-text-field>
      </v-list-item>
      <v-list-item>
        <v-text-field :label="$t('SettingsItems.TopPosition')" type="number" v-model="top" @input="updateObj()" style="margin-right: 35px"></v-text-field>
        <v-text-field :label="$t('SettingsItems.LeftPosition')" type="number" v-model="left" @input="updateObj()"></v-text-field>
      </v-list-item>
      <v-list-item>
        <v-text-field :label="$t('SettingsItems.Angle')" type="number" v-model="angle" @input="updateObj()"></v-text-field>
      </v-list-item>
      <v-list-item>
        <v-slider
            v-model="opacity"
            :label="$t('SettingsItems.Opacity')"
            @input="updateObj"
            step="0.01"
            min="0.01"
            max="1"
        >
        </v-slider>
      </v-list-item>
      <v-list-item class="justify-center">
        <v-btn :color="colorFill" @click="fillColorPicker = !fillColorPicker; strokeColorPicker = false" style="margin-right: 35px"></v-btn>
        <v-btn :color="colorStroke" @click="strokeColorPicker = !strokeColorPicker; fillColorPicker = false"></v-btn>
      </v-list-item>
      <v-list-item class="justify-center">
        <v-color-picker
            v-if="fillColorPicker"
            v-model="colorFill"
            flat
            @input="updateObj()"
        ></v-color-picker>
        <v-color-picker
            v-if="strokeColorPicker"
            v-model="colorStroke"
            flat
            @input="updateObj()"
        ></v-color-picker>
      </v-list-item>
      <v-list-item>
        <v-text-field :label="$t('SettingsItems.BorderThickness')" type="number" :min="0" v-model="strokeWidth" @input="updateObj()"></v-text-field>
      </v-list-item>
      <mask-order-controls :fcanvas="fcanvas" :obj="obj"></mask-order-controls>
      <div v-if="creator.indexOf('theme') !== -1">
        <v-list-item>
          <v-list-item-content>
            <v-btn @click="setAsMask">{{ maskMode ? $t('SettingsItems.DisableMask') : $t('SettingsItems.SetAsMask') }}</v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-btn v-if="maskMode" @click="importMaskedImageModal = true" v-t="'SettingsItems.ImportImageToPlace'"></v-btn>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
    <v-dialog v-model="importMaskedImageModal" max-width="500">
      <v-card>
        <v-card-title class="headline" v-t="'SettingsItems.ImportImage'"></v-card-title>
        <v-file-input v-model="inputFileImage" accept="image/*" :label="$t('SettingsItems.SelectedFile')" style="width: 95%"></v-file-input>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="importMaskedImageModal = false" v-t="'General.Cancel'"></v-btn>
          <v-btn color="primary' darken-1" text @click="addMaskedImage" v-t="'General.Confirm'"></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { fabric } from 'fabric'
import MaskOrderControls from '@/components/drawerRight/MaskOrderControls'

export default {
  props: [
    'obj',
    'refreshSetting',
    'creator',
    'fcanvas'
  ],
  name: 'SettingsCircle',
  components: { MaskOrderControls },
  data () {
    return {
      size: this.obj.width * this.obj.scaleX,
      colorFill: this.obj.fill,
      colorStroke: this.obj.stroke,
      strokeWidth: this.obj.strokeWidth,
      left: this.obj.left,
      top: this.obj.top,
      angle: this.obj.angle,
      fillColorPicker: false,
      strokeColorPicker: false,
      importMaskedImageModal: false,
      inputFileImage: null,
      maskMode: this.obj.isMask,
      opacity: this.obj.opacity
    }
  },
  created () {
    this.obj.on({
      'object:modified': (e) => {
        this.select(e)
      }
    })
  },
  watch: {
    refreshSetting () {
      this.updateLocalObj()
    },
    obj () {
      this.updateLocalObj()
    }
  },
  methods: {
    setAsMask () {
      if (this.obj.isMask) {
        this.fcanvas.getObjects().forEach((object) => {
          if (!object.isMask && object.customClipPathId === this.obj.customClipPathId) {
            this.fcanvas.remove(object)
          }
        })
        this.obj.customClipPathId = ''
        this.obj.isMask = false
        this.maskMode = false
      } else {
        this.obj.customClipPathId = '_' + Math.random().toString(36).substr(2, 9)
        this.obj.isMask = true
        this.maskMode = true
      }
    },
    addMaskedImage () {
      var oldImg = this.fcanvas.getObjects().find((obj) => { return obj.customClipPathId === this.obj.customClipPathId && !obj.isMask })
      this.fcanvas.remove(oldImg)
      this.importMaskedImageModal = false
      const reader = new FileReader()
      reader.onload = () => {
        var newImage = new Image()
        newImage.src = reader.result
        newImage.addEventListener('load', () => {
          var imgInstance = new fabric.Image(newImage, {
            left: this.obj.left,
            top: this.obj.top,
            originX: 'center',
            originY: 'center'
          })
          imgInstance.customClipPathId = this.obj.customClipPathId
          imgInstance.isMask = false
          imgInstance.clipPath = this.obj
          imgInstance.scaleToWidth(100)
          this.fcanvas.add(imgInstance)
          this.fcanvas.renderAll()
        })
      }
      reader.readAsDataURL(this.inputFileImage)
    },
    updateLocalObj () {
      this.size = this.obj.width * this.obj.scaleX
      this.colorFill = this.obj.fill
      this.colorStroke = this.obj.stroke
      this.strokeWidth = this.obj.strokeWidth
      this.left = this.obj.left
      this.top = this.obj.top
      this.angle = this.obj.angle
      this.opacity = this.obj.opacity
    },
    updateObj () {
      this.obj.angle = this.angle
      this.obj.scale(this.size / this.obj.width)
      this.obj.left = this.left
      this.obj.top = this.top
      this.obj.set('fill', this.colorFill)
      this.obj.set('stroke', this.colorStroke)
      this.obj.strokeWidth = parseInt(this.strokeWidth)
      this.obj.opacity = this.opacity
      this.updateLocalObj()
      this.$emit('renderAll')
    }
  }
}
</script>

<style scoped>

</style>
